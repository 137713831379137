import axios from 'axios'

const instance = axios.create({
    // baseURL: 'https://www.taskicabs.com/adminbackend/servicePartner'
    // baseURL: 'https://stagesp.taski.in/web'
    baseURL: 'https://sp.taski.in/web'
    // baseURL:'http://localhost:2020/web',
    
})

const token = localStorage.getItem('vendorLoginToken');
if(token) {
    instance.defaults.headers.common['token'] = token
}
export default instance
