const moment = require('moment');

export const VueUtil = {
    created() {
        this.$root.$refs.util = this;
    },
    methods: {
        getDate: function (millisecond, format) {

            if (typeof millisecond === 'string')
                millisecond = parseFloat(millisecond)

            const days = ['Yesterday', 'Today', 'Tomorrow'];
            const now = moment().startOf('day');
            const date = moment(millisecond).startOf('day');
            const diff = date.diff(now, 'days');

            let f1 = "hh:mm A";
            let f2 = "DD MMM YYYY hh:mm A";
            let s = ', ';

            if (format !== undefined) {
                if (Object.prototype.hasOwnProperty.call(format, 'f1'))
                    f1 = format.f1
                if (Object.prototype.hasOwnProperty.call(format, 'f2'))
                    f2 = format.f2
                if (Object.prototype.hasOwnProperty.call(format, 's'))
                    s = format.s
            }

            if ([-1, 0, 1].includes(diff)) {
                return days[diff + 1] + s + moment(millisecond).format(f1)
            } else {
                return moment(millisecond).format(f2)
            }
        },
        convertDate: function (date, format1, format2) {
            return moment(date, format1).format(format2)
        },
        convertToMillisecond: function (date, format1) {
            return moment(date, format1).valueOf()
        },
        convertToMillisecondEnd: function (date, format1) {
            return moment(date, format1).endOf('day').valueOf()
        },
        print: function (data) {
            if (typeof data === 'object')
                console.log(JSON.stringify(data))
        },
        customDate(date, format) {
            const newDate = parseInt(date);
            return moment(newDate).format(format);
        },

        setDefaultMeta() {
            return {
                title: "Whitelabel Corporate Portal - taSki",
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Employee and Corporate Mobility - airport, outstation & local corporate car rental services Pan India - Redefining corporate mobility services - taSki'
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: 'Employee Mobility Services, Corporate Mobility, Office Commute, Fleet Management Services, Airport Transfers, Corporate Car Rental, Event Car bookings, Outstation Car Rental, Business Travel Solutions, Corporate Fleet, EV Fleet for Corporates'
                    },
                    {
                        vmid: 'keyphrase',
                        name: 'keyphrase',
                        content: 'affordable car rental, car hire in India, cab hire, airport car rental, cheap cab for hire, radio taxi cab, radio taxi services, car rental by hour'
                    },
                    {
                        vmid: 'google-site-verification',
                        name: 'google-site-verification',
                        content: 'XnbCBcdHQSNkRHmtppKN3EF7UvNtRFYKVYpMy0j-I_8'
                    },
                    {
                        vmid: 'google-site-verification2',
                        name: 'google-site-verification',
                        content: '8_mLtDD0b_fO4JQMYOsDnPDlxuOc_r6PLF9BI4GvhSk'
                    },
                    {charset: 'utf-8'},
                    {property: 'og:title', content: "Redefining Corporate Mobility - taSki"},
                    {property: 'og:site_name', content: 'Redefining Corporate Mobility - taSki'},
                    {property: 'og:type', content: 'website'}
                ]
            };
        },

        msToTime(duration) {
            let seconds = Math.floor((duration / 1000) % 60),
                minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
                days = Math.floor(duration / 86400000);

            if(duration < 60000) {
                return seconds + ' seconds ago';
            } else if(duration < 3600000 && duration >= 60000) {
                return minutes + ":" + (seconds < 10 ? '0' : '') + seconds + ' minutes ago';
            } else if(duration < 86400000 && duration >= 3600000) {
                return hours + ":" + (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds + ' hours ago';
            } else if(duration >= 86400000) {
                return days + (days > 1 ? ' days, ' : ' day, ') + (hours < 10 ? '0' : '') + hours + ":" + (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds + ' hours ago';
            }

        },

        getHoursMinutes(minutes) {
            let h = Math.floor(minutes / 60);
            let m = minutes % 60;
            h = h < 10 ? '' + h : h;
            m = m < 10 ? '' + m : m;
            h = (isNaN(h) ? 0 : h);
            m = (isNaN(m) ? 0 : m);
            return `${h} hrs ${m} mins`;
        },

    }
};